body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-project-link li {
  border-left: 7px solid rgb(103, 226, 162);
  padding-left: 9px;
}

/* Custom scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ababad;
}

::-webkit-scrollbar {
  height: 12px;
  width: 8px;
}
::-webkit-scrollbar-track-piece {
  background: rgba(9, 30, 66, 0.13);
  border-radius: 10px;
}
