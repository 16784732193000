.list-drag-ghost {
  cursor: pointer !important;
  transform: rotateZ(3deg);
}

.list-drag-ghost .list-drag-handle {
  background-color: rgb(249, 249, 249) !important;
}

.smooth-dnd-ghost.vertical {
  transform: rotateZ(3deg);
}

.drop-preview {
  background-color: rgb(204, 204, 204);
  border-radius: 3px;
  margin-bottom: 8px;
}
